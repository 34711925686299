exports.components = {
  "component---src-pages-darum-tsx": () => import("./../../../src/pages/darum.tsx" /* webpackChunkName: "component---src-pages-darum-tsx" */),
  "component---src-pages-en-inspiration-tsx": () => import("./../../../src/pages/en/inspiration.tsx" /* webpackChunkName: "component---src-pages-en-inspiration-tsx" */),
  "component---src-pages-en-reason-tsx": () => import("./../../../src/pages/en/reason.tsx" /* webpackChunkName: "component---src-pages-en-reason-tsx" */),
  "component---src-pages-en-results-tsx": () => import("./../../../src/pages/en/results.tsx" /* webpackChunkName: "component---src-pages-en-results-tsx" */),
  "component---src-pages-ergebnisse-tsx": () => import("./../../../src/pages/ergebnisse.tsx" /* webpackChunkName: "component---src-pages-ergebnisse-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inspiration-tsx": () => import("./../../../src/pages/inspiration.tsx" /* webpackChunkName: "component---src-pages-inspiration-tsx" */),
  "component---src-templates-sanity-page-tsx": () => import("./../../../src/templates/sanityPage.tsx" /* webpackChunkName: "component---src-templates-sanity-page-tsx" */)
}

